// Cyborg 5.2.2
// Bootswatch


// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" !default;
@if $web-font-path {
  @import url($web-font-path);
}

:root {
  color-scheme: dark;
}

// Navbar

.navbar {
  &.bg-primary {
    border: 1px solid $gray-700;
  }

  &.bg-dark {
    background-color: $body-bg !important;
    border: 1px solid $gray-700;
  }

  &.bg-light {
    background-color: $gray-500 !important;
  }

  &.fixed-top {
    border-width: 0 0 1px;
  }

  &.fixed-bottom {
    border-width: 1px 0 0;
  }
}

// Buttons

.btn {
  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value linear-gradient(180deg, mix($white, $value, 15%), $value) repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}

// Forms

legend {
  color: $white;
}

.form-control {
  color-scheme: light;
  background-clip: border-box;

  &:disabled,
  &[readonly] {
    border-color: transparent;
  }
}

// Navs

.nav-tabs,
.nav-pills {
  .nav-link {
    color: $white;

    &:hover {
      background-color: $gray-700;
    }

    &.disabled,
    &.disabled:hover {
      color: $nav-link-disabled-color;
      background-color: transparent;
    }

    &.active {
      background-color: $primary;
    }
  }
}

.breadcrumb {
  a {
    color: $white;
  }
}

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Indicators

.alert {
  color: $white;
  border: none;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value linear-gradient(180deg, mix($white, $value, 15%), $value) repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}

.badge {
  &.bg-dark {
    color: $gray-900;
  }
}

// Containers

.list-group-item-action {

  &:hover {
    border-color: $primary;
  }
}

.popover {
  &-title {
    border-bottom: none;
  }
}
