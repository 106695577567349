// Cyborg 5.2.2
// Bootswatch

$theme: "cyborg" !default;

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #adafae !default;
$gray-500: #888 !default;
$gray-600: #555 !default;
$gray-700: #282828 !default;
$gray-800: #222 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #2a9fd6 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #c00 !default;
$orange:  #fd7e14 !default;
$yellow:  #f80 !default;
$green:   #77b300 !default;
$teal:    #20c997 !default;
$cyan:    #93c !default;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-800 !default;
$dark:          $gray-400 !default;

$min-contrast-ratio:   2.25 !default;


// Body

$body-bg:       #060606 !default;
$body-color:    $gray-400 !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif:  Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;
$h1-font-size:            4rem !default;
$h2-font-size:            3rem !default;
$h3-font-size:            2.5rem !default;
$h4-font-size:            2rem !default;
$h5-font-size:            1.5rem !default;
$headings-color:          $white !default;

// Tables

$table-color:                   $white !default;
$table-accent-bg:               rgba($white, .05) !default;
$table-hover-bg:                rgba($white, .075) !default;
$table-border-color:            $gray-700 !default;
$table-dark-bg:                 $gray-500 !default;
$table-dark-border-color:       darken($gray-500, 7.5%) !default;

$table-bg-scale:              0% !default;

// Buttons

$input-btn-padding-x:       1rem !default;

// Forms

$input-bg:                          $white !default;
$input-disabled-bg:                 $gray-400 !default;

$input-color:                       $gray-900 !default;
$input-border-color:                $white !default;
$input-border-width:                0 !default;

$input-group-addon-color:           $white !default;
$input-group-addon-bg:              $gray-700 !default;
$input-group-addon-border-color:    transparent !default;

$form-check-input-bg:                     $white !default;
$form-check-input-border:                 none !default;

$form-file-button-color:          $white !default;

// Dropdowns

$dropdown-bg:                    $gray-700 !default;
$dropdown-divider-bg:            $gray-800 !default;
$dropdown-link-color:            $white !default;
$dropdown-link-hover-color:      $white !default;
$dropdown-link-hover-bg:         $primary !default;

// Navs

$nav-tabs-border-color:                       $table-border-color !default;
$nav-tabs-link-hover-border-color:            $nav-tabs-border-color !default;
$nav-tabs-link-active-color:                  $white !default;
$nav-tabs-link-active-bg:                     $nav-tabs-border-color !default;
$nav-tabs-link-active-border-color:           $nav-tabs-border-color !default;

// Navbar

$navbar-dark-hover-color:           $white !default;

// Pagination

$pagination-color:                     $white !default;
$pagination-bg:                        $gray-700 !default;
$pagination-border-color:              transparent !default;
$pagination-hover-color:               $white !default;
$pagination-hover-bg:                  $primary !default;
$pagination-hover-border-color:        $pagination-border-color !default;
$pagination-disabled-bg:               $pagination-bg !default;
$pagination-disabled-border-color:     $pagination-border-color !default;

// Cards

$card-bg:                           $gray-700 !default;

// Tooltips

$tooltip-bg:                        $gray-700 !default;
$tooltip-opacity:                   1 !default;

// Popovers

$popover-bg:                        $gray-700 !default;

// Toasts

$toast-color:                       $white !default;
$toast-background-color:            $gray-800 !default;
$toast-border-color:                $gray-700 !default;
$toast-header-color:                $body-color !default;
$toast-header-background-color:     $toast-background-color !default;
$toast-header-border-color:         $toast-border-color !default;

// Modals

$modal-content-bg:                  $gray-800 !default;
$modal-header-border-color:         $gray-700 !default;

// Progress bars

$progress-bg:                       $gray-700 !default;

// List group

$list-group-color:                  $white !default;
$list-group-bg:                     $gray-800 !default;
$list-group-border-color:           $gray-700 !default;
$list-group-hover-bg:               $primary !default;
$list-group-disabled-bg:            $gray-700 !default;
$list-group-action-color:           $white !default;
$list-group-action-active-bg:       $primary !default;

// Breadcrumbs

$breadcrumb-padding-y:              .375rem !default;
$breadcrumb-padding-x:              .75rem !default;
$breadcrumb-bg:                     $gray-700 !default;
$breadcrumb-border-radius:          .25rem !default;

// Close

$btn-close-color:            $white !default;
$btn-close-opacity:          .6 !default;
$btn-close-hover-opacity:    1 !default;

// Code

$pre-color:                         inherit !default;
